<template>
  <div>
    <pdf :src="pdfUrl"></pdf>
  </div>
</template>

<script>
import { pdf } from 'vue-pdf';

export default {
  components: {
    pdf
  },
  data() {
    return {
      pdfUrl: '/Жан-Огист-Доминик_Енгр.pdf'
    };
  }
};
</script>


